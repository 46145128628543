const createChart = typeof window !== 'undefined' && require('lightweight-charts').createChart

export const defaultChartScale = 'W'

export const chartScales = [
  {
    name: 'H',
    interval: 'minute',
    datapoints: 60,
    steps: 1,
    label: '1H',
    seconds: 60 * 60
  },
  {
    name: 'D',
    interval: 'minute',
    datapoints: 24 * 6,
    steps: 10,
    label: '24H',
    seconds: 60 * 60 * 24 + 60 * 10
  },
  {
    name: 'W',
    interval: 'hour',
    datapoints: 24 * 7,
    steps: 1,
    label: '7D',
    seconds: 60 * 60 * 24 * 7 + 60 * 60
  },
  {
    name: 'M',
    interval: 'hour',
    datapoints: 6 * 30,
    steps: 4,
    label: '30D',
    seconds: 60 * 60 * 24 * 30 + 60 * 60 * 4
  },
  {
    name: 'Y',
    interval: 'day',
    datapoints: 183,
    steps: 2,
    label: '1Y',
    seconds: 60 * 60 * 24 * 365 + 60 * 60 * 24 * 2
  }
]

export default function renderChart (elem, locale, currency) {
  if (!elem || !createChart) {
    return
  }
  const chart = createChart(elem, {
    localization: {
      locale: locale,
      priceFormatter: price => price ? price.toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
    },
    priceScale: {
      borderVisible: false
    },
    timeScale: {
      borderVisible: false,
      timeVisible: true,
      secondsVisible: false,
      fixLeftEdge: true,
      lockVisibleTimeRangeOnResize: true
    },
    layout: {
      fontFamily: '"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", system-ui, sans-serif',
      backgroundColor: 'rgb(37, 38, 39)',
      textColor: 'rgba(255, 255, 255, .55)'
    },
    crosshair: {
      color: '#758696'
    },
    grid: {
      vertLines: {
        color: 'transparent'
      },
      horzLines: {
        color: 'transparent'
      }
    },
    handleScroll: false,
    handleScale: false
  })

  const extraseries = chart.addAreaSeries({
    topColor: 'rgba(125, 125, 125, 0.3)',
    bottomColor: 'rgba(125, 125, 125, 0)',
    lineColor: 'rgba(125, 125, 125, 1)',
    lineWidth: 2
  })

  const series = chart.addAreaSeries({
    topColor: 'rgba(246, 195, 88, 0.3)',
    bottomColor: 'rgba(246, 195, 88, 0)',
    lineColor: 'rgba(246, 195, 88, 1)',
    lineWidth: 2
  })

  return [chart, series, extraseries]
}

// export function drawData ({ chart, data, series, extradata, extraseries, delay = 0 }) {
//   if (extradata?.length && extraseries) {
//     try {
//       extradata.length && extraseries.update(extradata.pop())
//       extradata.length && extraseries.update(extradata.pop())
//       extradata.length && extraseries.update(extradata.pop())
//     } catch (e) { }
//   }
//   if (data?.length && chart && series) {
//     try {
//       data.length && series.update(data.pop())
//       data.length && series.update(data.pop())
//       data.length && series.update(data.pop())
//     } catch (e) { }
//   }
//   chart.timeScale().fitContent()
//   window.requestAnimationFrame(drawData.bind(this, ...arguments), delay)
// }
