import useTranslation from 'next-translate/useTranslation'

export const defaultProductUnit = 'gram'

export const productUnitRates = {
  gram: 1,
  ounce: 31.1034768
}

export default function resolveProductUnit (productUnit, amount = 1, factor = 1) {
  const productPriceResolution = (productUnitRates[productUnit] || productUnitRates.gram) * factor
  const { t } = useTranslation('default')

  let productUnitLabel = amount > 1 ? t('product-unit-gram_plural') : t('product-unit-gram')
  let productUnitShortLabel = t('product-unit-gram-short')
  let productUnitFractionDigits = 6
  switch (productUnit) {
    case 'ounce':
      productUnitLabel = amount > 1 ? t('product-unit-ounce_plural') : t('product-unit-ounce')
      productUnitShortLabel = t('product-unit-ounce-short')
      productUnitFractionDigits = 8
      break
  }

  return { productUnitLabel, productUnitShortLabel, productPriceResolution, productUnitFractionDigits }
}
