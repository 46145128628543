import style from './Nav.module.css'
import Link from 'next/link'
import classNames from 'classnames/bind'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useContext } from 'react'
import Updown from '../Updown'
import { useLivePrice } from '../../hooks/usePrices'
import resolveProductUnit from '../../helpers/resolveProductUnit'
import useAuth from '../../hooks/useAuth'
import useUser from '../../hooks/useUser'
import Router, { useRouter } from 'next/router'
import { LayoutContext } from './Layout'

export default function Nav () {
  const { t } = useTranslation('main')
  const router = useRouter()
  const { locale } = router
  const lang = locale.split('-')[0]
  const langExtension = lang !== 'en' ? '-' + lang : ''

  const c = classNames.bind(style)
  const { isAuthed, isTwoFaEnabled, setAuth } = useAuth()
  const { user } = useUser()

  const [layoutContext] = useContext(LayoutContext)
  const { productUnit, currency } = layoutContext
  const { productPriceResolution, productUnitShortLabel } = resolveProductUnit(productUnit, 1)

  const { price, updown } = useLivePrice(currency, productPriceResolution)

  useEffect(() => document.querySelectorAll(`.${style.category} li a`).forEach(a =>
    a.classList[a.href === window.location.toString() ? 'add' : 'remove'](style.active)))

  useEffect(() => document.querySelectorAll(`.${style.category} li a`).forEach(a =>
    a.addEventListener('click', () => { a.blur(); a.classList.add(style.active) })), [])

  const handleInstallClick = e => {
    if (window.deferredInstallPrompt) {
      e.preventDefault()
      e.stopPropagation()
      Router.push('/install')
    }
  }

  const isStandalone = typeof window !== 'undefined' && window.isStandalone

  return (
    <nav className={c('nav')}>
      <Link href={isAuthed ? '/portfolio' : '/sg'}>
        <a>
          <img src='/images/goldstrom-logo.png' alt={t('nav-homepage')} className={c('desktop')} />
        </a>
      </Link>
      <div className={c('column')}>
        <div className={c('category')}>
          <Link href='/sg'>
            <a>
              <h5>{t('nav-category-introduction')}</h5>
            </a>
          </Link>
          <ul>
            <li>
              <Link href='/sg'>
                <a>
                  <h6>{t('nav-homepage')}</h6>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/sg/tour'>
                <a>
                  <h6>{t('nav-tour')}</h6>
                  <span>{t('nav-tour-description')}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/explore'>
                <a>
                  <h6>{t('nav-explore')}</h6>
                  <span>{t('nav-explore-description')}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/sg/pricing'>
                <a>
                  <h6>{t('nav-pricing')}</h6>
                  <span>{t('nav-pricing-description')}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/sg/faq'>
                <a>
                  <h6>{t('nav-faq')}</h6>
                  <span>{t('nav-faq-description')}</span>
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className={c('category')}>
          <Link href='/sg/why-should-i-save-in-gold'>
            <a>
              <h5>{t('nav-category-learn')}</h5>
            </a>
          </Link>
          <ul>
            <li>
              <Link href={'/sg/singapore-guide-to-gold-savings-and-gold-investments' + langExtension}>
                <a>
                  <h6>{t('nav-singaporeguide')}</h6>
                  <span>{t('nav-singaporeguide-description')}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href={'/sg/why-should-i-save-in-gold' + langExtension}>
                <a>
                  <h6>{t('nav-savingingold')}</h6>
                  <span>{t('nav-savingingold-description')}</span>
                </a>
              </Link>
            </li>
            {/* <li>
              <Link href='/sg/gold-vs-real-estate'>
                <a>
                  <h6>{t('nav-goldvsrealestate')}</h6>
                  <span>{t('nav-goldvsrealestate-description')}</span>
                </a>
              </Link>
            </li> */}
            <li>
              <Link href={'/sg/gold-versus-inflation' + langExtension}>
                <a>
                  <h6>{t('nav-goldinflation')}</h6>
                  <span>{t('nav-goldinflation-description')}</span>
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className={c('category')}>
          <Link href='/sg/about'>
            <a>
              <h5>{t('nav-category-about')}</h5>
            </a>
          </Link>
          <ul>
            <li>
              <Link href='/sg/about'>
                <a>
                  <h6>{t('nav-about')}</h6>
                  <span>{t('nav-about-description')}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/sg/partners'>
                <a>
                  <h6>{t('nav-partners')}</h6>
                  <span>{t('nav-partners-description')}</span>
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className={c('category', 'mobile')}>
          <h5>{t('nav-resources')}</h5>
          <ul>
            <li className={c('sub')}>
              <Link href='/sg/terms'>
                <a>{t('nav-terms')}</a>
              </Link>
            </li>
            <li className={c('sub')}>
              <Link href='/sg/privacy'>
                <a>{t('nav-privacy')}</a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={c('column')}>
        <div className={c('category')}>
          <Link href='/price'>
            <a>
              <h5>{t('nav-category-market')}</h5>
              {price?.value !== undefined &&
                <p className={c('price')}>
                  ${price.value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}/{productUnitShortLabel} ({updown > 0 ? '+' : ''}{(updown / price.value).toLocaleString(locale, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })}<Updown number={updown} />)
                </p>}
            </a>
          </Link>
          <ul>
            <li>
              <Link href='/price'>
                <a>
                  <h6>{t('nav-prices')}</h6>
                </a>
              </Link>
            </li>
            <li>
              <Link href='/news'>
                <a>
                  <h6>{t('nav-news')}</h6>
                </a>
              </Link>
            </li>
          </ul>
        </div>
        <div className={c('category', 'account', { highlight: !user?.FirstName })}>
          <Link href='/portfolio'>
            <a>
              <h5>{t('nav-category-account')}</h5>
            </a>
          </Link>
          {isAuthed && isTwoFaEnabled
            ? (
              <>
                {user && <p className={c('desktop')}>{user?.FirstName}</p>}
                {user && <p className={c('mobile')}>{t('nav-loggedinas')} {user?.FirstName + ' ' + user?.LastName}</p>}
                <ul>
                  <li>
                    <Link href='/portfolio'>
                      <a>
                        <h6>{t('nav-portfolio')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li className={c('highlight')}>
                    <Link href='/buy'>
                      <a>
                        <h6>{t('nav-buyandsell')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li className={c('highlight')}>
                    <Link href='/recurring'>
                      <a>
                        <h6>{t('nav-recurring')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/deposit'>
                      <a>
                        <h6>{t('nav-depositandwithdraw')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/sg/redeem'>
                      <a>
                        <h6>{t('nav-redeem')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/transactions'>
                      <a>
                        <h6>{t('nav-transactionhistory')}</h6>
                      </a>
                    </Link>
                  </li>
                  <hr />
                  {!isStandalone && (
                    <li>
                      <Link href='/gettheapp'>
                        <a onClick={handleInstallClick}>
                          <h6>{t('nav-app')}</h6>
                        </a>
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link href='/share'>
                      <a>
                        <h6>{t('nav-share')}</h6>
                      </a>
                    </Link>
                  </li>
                  <hr />
                  <li>
                    <Link href='/settings/account'>
                      <a>
                        <h6>{t('nav-account')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/settings/profile'>
                      <a>
                        <h6>{t('nav-profile')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/settings/security'>
                      <a>
                        <h6>{t('nav-security')}</h6>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <a href='#' onClick={e => { e.preventDefault(); setAuth() }}>
                      <h6>{t('nav-logout')}</h6>
                    </a>
                  </li>
                </ul>
              </>
            ) : (
              <div>
                <ul>
                  <li className={c('highlight')}>
                    <Link href='/signup'>
                      <a>
                        <h6>{t('nav-signup')}</h6>
                        <span>{t('nav-signup-description')}</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/login'>
                      <a>
                        <h6>{t('nav-login')}</h6>
                      </a>
                    </Link>
                  </li>
                  <hr />
                  {!isStandalone && (
                    <li>
                      <Link href='/gettheapp'>
                        <a onClick={handleInstallClick}>
                          <h6>{t('nav-app')}</h6>
                        </a>
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link href='/share'>
                      <a>
                        <h6>{t('nav-share')}</h6>
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
        </div>
        <div className={c('category', 'mobile')}>
          <ul>
            <li className={c('sub')}>
              <a href='tel:+6561003040'>{t('nav-phone')} <x-nowrap>+65 6100 3040</x-nowrap></a>
            </li>
            <li className={c('sub')}>
              <Link href='/sg/contact'>
                <a>{t('nav-contact')}</a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
